import Layout from 'components/layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
// import styled from 'styled-components';

import Head from 'components/head';

import Section from '~v2/section';
import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import { MarkdownContent, MarkdownContainer } from 'blog.css.js';
// import { colors } from 'constants/theme';

const Index = ({
  data: {
    manifestoJson: { title, copy, left_rail_bg },
  },
}) => (
  <Layout>
    <Head
      pageTitle={title}
      pageDescription="Trace designs software that shines illuminates; it shines a light to give your product security, and to highlight what makes it special. Read the manifesto!"
    />
    <Wrapper>
      <TitleContainer wordSpacing="1000px">
        <Title as="h1">{title}</Title>
      </TitleContainer>
      <Section
        boundsProps={{
          fluid: true,
          style: { width: '100%' },
          TABLET_DOWN: { padding: '0 2rem' },
          TABLET: { padding: '0 2rem' },
          DESKTOP: { padding: '0 2rem' },
          DESKTOP_UP: { padding: '0' },
        }}
        left={{
          boxProps: {
            fluid: true,
            DESKTOP_DOWN: { width: '0%' },
            DESKTOP_UP: { width: '15%' },
          },
          backgroundProps: {
            image: left_rail_bg,
          },
        }}
        right={{
          children: (
            <MarkdownContainer>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: copy.childMarkdownRemark.html,
                }}
              />
            </MarkdownContainer>
          ),
          boxProps: {
            fluid: true,
            shiftUp: true,
            DESKTOP_DOWN: { width: '100%' },
            DESKTOP_UP: { width: '85%' },
          },
        }}
      />
    </Wrapper>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query ManifestoQuery {
    manifestoJson {
      title
      copy {
        childMarkdownRemark {
          html
        }
      }
      left_rail_bg {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
